import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: 'activation-email',
  data() {
    return {
      payload: {}
    }
  },

  computed: {
    ...mapGetters({
      showActivationEmailLoading: `auth/showActivationEmailLoading`
    }),
  },

  created() {
    this.payload = {
      activation_code: this.$route.query.activation_code,
      email: this.$route.query.email
    }

    if (Object.keys(this.$route.query).length > 0 &&
      this.payload.activation_code !== '' &&
      this.payload.email !== '') {
      this.submitActivationEmail();
    }
  },

  methods: {
    ...mapActions({
      activateEmail: `auth/ACTIVATION_EMAIL`,
      fetchUserData: `profile/FETCH_USER_DATA`,
    }),

    ...mapMutations({
      setToken: `auth/SET_TOKEN`,
      changePopupActivationEmail: `auth/CHANGE_POPUP_ACTIVATION_EMAIL`,
      changePopupRegister: `auth/CHANGE_POPUP_REGISTER`,
      changePopupSignIn: `auth/CHANGE_POPUP_SIGN_IN`,
    }),

    navigateToSingIn() {
      this.changePopupActivationEmail(false);
      setTimeout(()=> {
        this.changePopupSignIn(true);
      },200)
      this.$router.replace({'query': null});
    },

    navigateToHome() {
      this.changePopupActivationEmail(false);
      this.$router.replace({'query': null});
    },

    submitActivationEmail() {
      this.activateEmail(this.payload)
        .then((payload) => {
          this.setToken(payload.data.access_token)

          this.$toasted.success(this.$t('successActivationEmail'));
          this.$nextTick(()=> {
            this.fetchUserData();
          })
        }).catch(error => {
        let formattedMessage = null;

        if (error && error.response) {
          if (error.response.data.message) {
            formattedMessage = error.response.data.message;
          }

          if (error.response.data.error) {
            formattedMessage = error.response.data.error.message;
          }

          if (error.response.data.errors) {
            Object.keys(error.response.data.errors).forEach(errorKey => {
              error.response.data.errors[errorKey].forEach((line) => {
                formattedMessage += `\r\n${line}`;
              });
            });
          }
        }

        formattedMessage && this.$toasted.error(formattedMessage);
      });
    }
  }
}
